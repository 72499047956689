// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../cw-event';
import * as _moment from 'moment';
import {UserPermissionsService} from '@global/services/user-permissions.service';

const moment = _moment;

@Injectable({providedIn: 'root'})
export class ContactsService {
    // Aktuelle Contact-Id
    public contactId: number;

    // Subject (Observable) definieren "Ticket-Verantwortlichkeit wurde geprüft"
    public eventCheckedTicketResponsibility = new Subject<CWEvent>();

    // Readonly-Wert des Kontakts weitergeben, um den Edit-Button in anderen Komponenten ein/-auszublenden
    public contactReadonlyChanged = new Subject<boolean>();

    // Konstruktor (inkl. dependency injection)
    constructor(
        private backendService: BackendService,
        private appCore: AppCoreService,
        private userPermissionsService: UserPermissionsService,
    ) {}

    /**
     * @brief   Mehrere Kontakte einer Einrichtung laden (für Listenansicht)
     * @param formData
     * @param   Object  formData   ID der Einrichtung und Pagination
     * @returns  Observable<unknown>
     */
    loadContactsListForInstitution(formData: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'ReportsContactsList/index/',
            formData,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * @brief   Mehrere Kontakte einer Person laden (für Listenansicht)
     * @param formData
     * @param   number  personId        ID der Person
     * @param   number  gridPage        Zu ladende Seite für Pagination
     * @returns  Observable<unknown>
     */
    loadContactsListForPerson(formData: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'ReportsContactsList/index/',
            formData,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * @brief   Details eines Kontakts laden
     * @param   string  controller          Name des Backend-Controllers
     * @param   number  contactId           Eindeutige Kontakt-ID
     * @param controller
     * @param contactId
     * @param newContactOptions
     * @param   array   newContactOptions   Optionen für Kontakt-Neuanlage (Kontakttyp, Pesonen-ID, Einrichtungs-ID, ...)
     */
    loadDetails(controller: string, contactId: number, newContactOptions?: object): Observable<unknown> {
        // Speicher Kontakt-ID
        this.contactId = contactId;
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            controller + '/details/' + contactId,
            newContactOptions,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * @brief   Mehrere Kontakte eines Tages laden (für Listenansicht)
     * @param formData
     * @param   Object  formData    Optionen
     * @returns  Observable<any>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadContactsListForEmployee(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'ReportsDailyreports/getContacts/',
            formData,
        );
        // Observable (an Komponente) zurückliefern
        return postRequest$;
    }

    /**
     * @param formData
     * @brief   Tagesbericht laden
     * @returns  Observable<any>
     */
    loadDailyreportOfDate(formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'ReportsDailyreports/getDailyreportActivities/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * prepare backend request to load employee list
     */
    getVisibleEmployeesList(): Observable<any> {
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'ReportsDailyreports/getVisibleEmployeesList/',
        );
        return getRequest$;
    }

    /**
     * @brief   Kontakt speichern
     * @param   string  controller          Name des Backend-Controllers
     * @param   number  contactId           Eindeutige Kontakt-ID
     * @param   any     formData            Formular-Daten, die submitted werden
     * @param controller
     * @param contactId
     * @param formData
     * @param contactItems
     * @param participants
     * @param unlock
     * @param   any     contactItems       Array der ausgewählten Muster
     * @param   any     participants        Array der ausgewählten Personen
     */
    saveDetails(
        controller: string,
        contactId: number,
        formData: any,
        contactItems: any,
        participants: any[] = [],
        unlock: boolean = null,
    ): Observable<unknown> {
        // Definiert, ob alle Kontakte aus einem Mehrfachkontakt bearbeitet werden oder nur der ausgewählte Kontakt
        const allowEditMultiContact: boolean = this.userPermissionsService.getPermissionValue('allowEditMultiContact');

        // Prüfe ob Muster zum Kontakt gespeichert werden sollen
        if (typeof contactItems !== 'undefined' && Array.isArray(contactItems) && contactItems.length > 0) {
            formData.contact_items = contactItems;
        }

        // Prüfe ob der Kontakt bei mehreren Personen gespeichert werden soll
        if (Array.isArray(participants) && participants.length > 0 && (allowEditMultiContact || contactId === 0)) {
            formData.participants = participants;
        }

        if (unlock !== null && unlock) {
            formData.unlock = unlock;
        }

        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            controller + '/edit/' + contactId,
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param controller
     * @param formData
     * @brief   Kontakte löschen
     * @returns  Observable<unknown>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    deleteContacts(controller: string, formData: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(controller + '/delete/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param target
     * @param data
     * @brief       Falls Daten erfolgreich geändert wurden
     * @details     Sendet aktualisierten Datensatz per Event, damit andere
     *              Module (z.B. Listen) darauf reagieren können
     */
    dataChanged(target: string, data: any): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'contacts',
            target,
            data,
        };
        this.appCore.appDataChanged.next(eventData);
    }

    /**
     * Versende die Daten eines Kontakts (Support-Ticket) per Mail
     * @param   string      controller  Name des Backend-Controllers
     * @param   string      recipient   Empfänger der Mail
     * @param   string      subject     Betreff der Mail
     * @param   number      contactId   ID des Kontakts(!)
     * @param controller
     * @param recipient
     * @param subject
     * @param contactId
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    forwardMail(controller: string, recipient: string, subject: string, contactId: number): Observable<unknown> {
        const postData: any = {
            recipient,
            subject,
            contactId,
        };
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            controller + '/forwardMail/',
            postData,
        );
        return postRequest$;
    }

    /**
     * Verantwortlichkeit eines Tickets wurde geprüft.
     * Wird benötigt, damit z. B. die Toolbar in Abhängigkeit der Ticket-
     * Verantwortung konfiguriert werden kann.
     * @param   number      contactId       ID des Kontakts
     * @param   boolean     ownTicket       <true> Eigenes Ticket
     *                                      <false> Fremdes Ticket
     * @param   string      state           Ticketzustand (Listentry)
     * @param contactId
     * @param ownTicket
     * @param state
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    checkedTicketResponsibility(contactId: number, ownTicket: boolean, state: string): void {
        const eventData: CWEvent = {
            sender: 'contactsService',
            target: null,
            data: {
                contactId,
                ownTicket,
                state,
            },
        };
        this.eventCheckedTicketResponsibility.next(eventData);
    }

    /**
     * Speichert Übernahme eines Tickets
     *
     * Employee-ID des übernehmenden Mitarbeiters muss nicht übergeben werden,
     * da dies im Backend automatisch auf den aktiven User gesetzt wird.
     * @param   string      controller              Name des Backend-Controllers
     * @param   number      contactId               ID des Kontakts
     * @param controller
     * @param contactId
     * @param checkLock
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    saveTakeover(controller: string, contactId: number, checkLock: boolean): Observable<unknown> {
        const postData: any = {
            contactId,
            checkLock,
        };
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            controller + '/saveTakeover/',
            postData,
        );
        return postRequest$;
    }

    /**
     * Speichert Wiedereröffnung eines Tickets
     * @param   string      controller              Name des Backend-Controllers
     * @param   number      contactId               ID des Kontakts
     * @param controller
     * @param contactId
     * @author  Lennart Bentz <l.bentz@pharmakon.software>
     */
    saveReopen(controller: string, contactId: number): Observable<unknown> {
        const postData: any = {contactId};
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            controller + '/saveReopen/',
            postData,
        );
        return postRequest$;
    }

    /**
     * @param contactId
     * @brief
     * @returns  Observable<unknown>
     * @author
     */
    getContactLinks(contactId): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.getRequest(
            'ContactsLinks/getContactsWithLinkInformation/' + contactId,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param contactId
     * @brief   Selektiere bestimmten Benutzer
     * @see     Diese Filter-Funktion existiert auch in people.service.ts,
     *          da sowohl in E-Modulen als auch P-Modulen benötigt.
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    executeFilterUserById(contactId: number): Observable<unknown> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<unknown> = this.backendService.getRequest(
            'ContactsLinks/filterById/' + contactId,
        );
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @brief   Kontakt speichern
     * @param   string  controller          Name des Backend-Controllers
     * @param   number  contactId           Eindeutige Kontakt-ID
     * @param   any     formData            Formular-Daten, die submitted werden
     * @param formData
     * @param   any     contact_items       Array der ausgewählten Muster
     * @param   any     participants        Array der ausgewählten Personen
     */
    saveContactHelpentry(formData: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'ContactsHelpentries/save/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Kontakt speichern
     * @param   string  controller          Name des Backend-Controllers
     * @param   number  contactId           Eindeutige Kontakt-ID
     * @param   any     formData            Formular-Daten, die submitted werden
     * @param contactId
     * @param   any     contact_items       Array der ausgewählten Muster
     * @param   any     participants        Array der ausgewählten Personen
     */
    loadContactHelpentries(contactId: number): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.getRequest(
            'ContactsHelpentries/index/' + contactId,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param formData
     * @brief   ???
     */
    deleteContactHelpentry(formData: any): Observable<unknown> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'ContactsHelpentries/delete/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param type
     * @param contactId
     * @brief   ???
     */
    checkLock(type: string, contactId: number): Observable<unknown> {
        const formData = {
            type,
            id: contactId,
        };

        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest(
            'EntityLocks/checkEntityLock/',
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param type
     * @param contactId
     * @param lock
     * @brief   ???
     */
    lockEntity(type: string, contactId: number, lock: boolean): Observable<unknown> {
        const formData = {
            type,
            id: contactId,
            lock,
        };

        // POST-Request über BackendService senden
        const postRequest$: Observable<unknown> = this.backendService.postRequest('EntityLocks/lockEntity/', formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    // Prüfen ob gesuchte PErson existiert
    isPersonEligibleForCollectiveEntry(key: string, note: string) {
        // GET-Request über BackendService senden
        const getRequest$: Observable<unknown> = this.backendService.postRequest(
            'PeopleContacts/isPersonEligibleForCollectiveEntry/',
            {
                key,
                note,
            },
        );
        // Observable (an Komponente) zurückliefern
        return getRequest$;
    }

    /**
     * @brief Prüfen ob die Kontakte gelöscht werden dürfen und graut den Delete Button aus
     * @param   {any}  checkedContacts Von Benutzers ausgewählten Kontakte
     * @param   {number}  employeeId Employe ID des Benutzers
     * @param   {number}  DeleteContactWithinDays Konfiguration, wie lange die Kontakte gelöscht werden dürfen
     * @returns {boolean}
     * @author  Julia Zitzmann <j.zitzmann@pharmakon.software>
     */
    checkDeleteUsersContacts(checkedContacts: any, employeeId: number, DeleteContactWithinDays: number): boolean {
        // Für jeden angekreuzten Kontakt Bedingung prüfen
        let disableDeleteButton = false;
        for (let i = 0; i < checkedContacts.length; i++) {
            const contact = checkedContacts[i];
            const contactOwnerEmployeeID = contact.employee_id;
            // Prüft ob der Kontakt gelöscht werden kann, anhand vom Alter
            const validDeleteDate = this.checkValidDeleteDate(moment(contact.contact_start), DeleteContactWithinDays);
            if (validDeleteDate && contactOwnerEmployeeID == employeeId) {
                disableDeleteButton = false;
            } else {
                disableDeleteButton = true;
                break;
            }
        }
        return disableDeleteButton;
    }

    /**
     * @brief Prüfen ob der Kontakt noch nicht zu alt ist, und gelöcht werden darf
     * @param  {any}  contactStartDate Erstelldatum des Kontakts
     * @param  {number}  DeleteContactWithinDays Konfiguration, wie lange die Kontakte gelöscht werden dürfen
     * @returns {boolean}
     * @author  Julia Zitzmann <j.zitzmann@pharmakon.software>
     */
    private checkValidDeleteDate(contactStartDate: any, DeleteContactWithinDays: number) {
        if (DeleteContactWithinDays === 0) {
            return true;
        }
        // berechne die DeleteContactWithinDays variale in Millisekundne
        const DaysInMillis = DeleteContactWithinDays * 24 * 60 * 60 * 1000;
        // Heute - DeleteContactWithinDays (wie alt ein Kontakt max seien darf bei der Löschung)
        const allowDeleteTillDate = moment().subtract(DaysInMillis, 'milliseconds');

        if (contactStartDate > allowDeleteTillDate) {
            return true;
        }

        return false;
    }
}
