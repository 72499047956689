export const defaultEnvironment = {
    adminUsersEmployeeDatafields: {
        account_payable: {
            disabled: false,
            required: false,
            visible: true,
        },
        erp_number_additional: {
            disabled: false,
            required: false,
            visible: false,
        },
        calendar_id: {
            disabled: false,
            required: false,
            visible: true,
        },
        city: {
            disabled: false,
            required: false,
            visible: true,
        },
        costcenter: {
            disabled: false,
            required: false,
            visible: true,
        },
        description: {
            disabled: false,
            required: false,
            visible: false,
        },
        exchangesync: {
            disabled: false,
            required: false,
            visible: false,
        },
        fax: {
            disabled: false,
            required: false,
            visible: false,
        },
        language_key: {
            disabled: false,
            required: false,
            visible: true,
        },
        mail: {
            disabled: false,
            required: false,
            visible: true,
        },
        phone1: {
            disabled: false,
            required: false,
            visible: true,
        },
        phone2: {
            disabled: false,
            required: false,
            visible: false,
        },
        phone3: {
            disabled: false,
            required: false,
            visible: false,
        },
        region_id: {
            disabled: false,
            required: true,
            visible: true,
        },
        regions: {
            disabled: false,
            required: false,
            visible: true,
        },
        street: {
            disabled: false,
            required: false,
            visible: false,
        },
        time_tracking_id: {
            disabled: false,
            required: false,
            visible: false,
        },
        zipcode: {
            disabled: false,
            required: false,
            visible: true,
        },
    },
    samplesInventoryLossNoteRequired: false,
    samplesInventoryReturnNoteRequired: false,
    samplesInventoryStocktakingNoteRequired: false,
    productsExcludeDivisions: false,
    allowAddNewOrderItems: true,
    allowDynamicSelectionSave: true,
    allowOrderDocumentUpload: false,
    allowOverwriteOrderStatusEditable: false,
    allowPharmaPilotUsers: true,
    allowSalesAnalysisProductView: false,
    allowChangeHierarchy: true,
    articleSearchColumns: 'CONCAT(erp_number, \'  \', pzn, \' - \', label, \' 1 \', Listentries.list_value)',
    autocorrectPhone: false,
    backendDebug: false,
    backendUrl: 'https://crm.localhost/crm-backend-ci/',
    budgetFilterEndYearOffset: 1,
    budgetFilterStartYear: 2018,
    businessYearStart: 4,
    characteristicsPopupEnabled: true,
    chartColors: ['#f0a762', '#8fbc8f', '#7798bf'],
    chartInstitutionsSales: ['sales-per-month'],
    combinedZipcodeCityInputEnabled: false,
    contactsCharacteristicsEnabled: false,
    appointmentTitleRequired: true,
    contactNoteRequired: false,
    appointmentNoteRequired: false,
    taskNoteRequired: false,
    contactEmployeeVisible: true,
    appointmentEmployeeVisible: true,
    taskEmployeeVisible: true,
    contactsDisableFutureDateForContacts: true,
    contactsNoSamplesForNewPeople: true,
    contactsSamplesIgnoreSampleAuthorizationForProductTypes: [],
    contactsSamplesSelectBatches: true,
    contactsSchedulingDuration: '01:00',
    contactsSchedulingMethod: 50,
    contactsSchedulingStartGap: 60,
    contactsSchedulingStartTime: '08:00',
    contactsSchedulingType: 'appointment',
    contactsShowTimeAppointment: true,
    contactsShowAppointmentTitle: true,
    contactsShowContactTitle: false,
    contactTitleRequired: false,
    contactsShowAppointmentPriority: true,
    contactsShowContactPriority: true,
    contactsShowTimeContact: false,
    contactsShowTimeRemote: true,
    contactsShowTimeTask: false,
    contactsShowTimeTicket: true,
    contactsShowTimeStamp: false,
    contactsUseDuration: true,
    contactsUseItems: true,
    contactsUseItemsConversationOrder: true,
    contactsHideSamplesForContactTypes: [],
    contactsShowNoteInInfo: false,
    contactsShowNoteInTopicsOfConversation: false,
    showGlobalSelectionPopup: true,
    customSortInitialDirection: '',
    customSortLabel: '',
    customSortLockedDirection: '',
    dailyreportAdditionalActivitiesEnabled: true,
    dailyreportCateringFixedRatesEnabled: false,
    dailyreportWorktimeEnabled: true,
    dailyreportExpenseCompleteStatusKey: 'complete',
    dailyreportExpenseLockStatusKey: 'closed',
    dailyreportExpenseStatesEnabled: false,
    dailyreportExpenseStatesWorkflow: false,
    dailyreportExpensesCompositionEnabled: false,
    dailyreportMileageEnabled: false,
    dailyreportMultipleJourneySectionsEnabled: false,
    dailyreportPrivateDistanceEnabled: false,
    dailyreportVehicleTypeEnabled: false,
    dailyreportVoucherTaxEnabled: false,
    defaultCurrency: '\u20ac',
    includeDecimalsSalesAndProductsAnalysis: true,
    applyChfCurrencyFormat: false,
    duplicatePanelInstitutionsDisplayedColumns: [
        'radio',
        'institution-name',
        'institution-address',
        'institution-communication',
        'institution-erp-number',
    ],
    duplicatePanelPeopleDisplayedColumns: [
        'radio',
        'person-title-name',
        'person-specialization-function',
        'person-institution-name',
        'person-institution-address',
    ],
    eVisit: false,
    edetailerLink: '',
    enableClearingDisplayLockedId: false,
    enableClearingMergeInstitutions: true,
    enableClearingMergePeople: true,
    enableClearingShowCompleteChangelogs: true,
    enableDuplicateCheckPeopleCopy: true,
    enableDuplicateCheckPeopleMove: true,
    enableDuplicateCheckUniqueMessage: false,
    enableGlobalHelpButtonAddLink: false,
    enableGlobalHelpSortInputField: false,
    enableInstitutionOpeninghoursTimeInput: false,
    enableInstitutionOpeninghoursVacations: false,
    enableInstitutionsDataAddDepartmentButton: true,
    enableInstitutionsDataAddPersonButton: false,
    enableTaskType: false,
    showProductErpNumber: false,
    enableTicketContactType: false,
    eventCostsColumns: [
        'event-cost-type-label',
        'voucher-text',
        'voucher-date',
        'event-cost-person',
        'empty',
        'cost-plan',
        'cost-actual',
        'delete',
    ],
    eventDataParticipantsMaximumMinValue: 0,
    eventsActualCostsDatafields: {
        cost_actual: {
            required: true,
            visible: true,
        },
        cost_gross: {
            required: true,
            visible: false,
        },
        distribute_costs: {
            required: false,
            visible: true,
        },
        employee_id: {
            required: false,
            visible: false,
        },
        event_cost_center: {
            required: false,
            visible: true,
        },
        event_cost_type: {
            required: true,
            visible: true,
        },
        people_ids: {
            required: false,
            visible: true,
        },
        tax_key: {
            required: true,
            visible: false,
        },
        use_net: {
            required: false,
            visible: false,
        },
        voucher_date: {
            required: false,
            visible: true,
        },
        voucher_text: {
            required: false,
            visible: true,
        },
    },
    eventsDatafields: {
        additional_location_info: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        city: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        cost_actual: {
            cwInputType: 'number',
            disabled: false,
            required: false,
            visible: false,
        },
        cost_calculation: {
            cwInputType: 'number',
            disabled: false,
            required: false,
            visible: false,
        },
        cost_plan: {
            cwInputType: 'number',
            disabled: false,
            required: false,
            visible: false,
        },
        create: {visible: true},
        edit: {visible: true},
        event_end: {
            cwInputType: 'date',
            disabled: false,
            required: true,
            visible: true,
        },
        event_implementation: {
            cwInputType: 'select',
            cwListentries: 'eventImplementation',
            disabled: false,
            required: false,
            visible: true,
        },
        event_implementation_comment: {
            cwInputType: 'textarea',
            disabled: false,
            required: false,
            visible: true,
        },
        event_notes: {
            cwInputType: 'textarea',
            disabled: false,
            required: false,
            visible: true,
        },
        event_number: {
            cwInputType: 'text',
            disabled: false,
            required: true,
            visible: false,
        },
        event_start: {
            cwInputType: 'date',
            disabled: false,
            required: true,
            visible: true,
        },
        event_status: {
            cwInputType: 'select',
            cwListentries: 'eventStatus',
            disabled: false,
            required: false,
            visible: true,
        },
        event_subtype: {
            cwInputType: 'select',
            cwListentries: 'eventSubtype',
            disabled: false,
            required: false,
            visible: true,
        },
        event_target: {
            cwInputType: 'radio',
            cwListentries: 'eventTarget',
            disabled: false,
            required: false,
            visible: true,
        },
        event_type: {
            cwInputType: 'select',
            cwListentries: 'eventType',
            disabled: false,
            required: true,
            visible: true,
        },
        event_workflow_comment: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        event_workflow_name: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        id: {
            cwInputType: 'text',
            disabled: true,
            required: true,
            visible: true,
        },
        name1: {
            cwInputType: 'text',
            disabled: false,
            required: true,
            visible: true,
        },
        name2: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        participants_maximum: {
            cwInputType: 'number',
            disabled: false,
            required: false,
            visible: true,
        },
        shortname: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        zipcode: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
    },
    eventsOverviewAppendDisplayedColumns: ['empty'],
    eventsOverviewPrependDisplayedColumns: [],
    eventsPlanCostsDatafields: {
        cost_plan: {
            required: true,
            visible: true,
        },
        event_cost_type: {
            required: true,
            visible: true,
        },
        tax_key: {
            required: true,
            visible: false,
        },
    },
    globalHelpTreeMaxLevels: 2,
    globalRegionsfilterActiveModules: [
        'dashboards',
        'institutions',
        'people',
        'maps-tour',
        'events',
        'reports-dailyreport',
        'reports-contacts-overview',
        'reports-samples-overview',
        'reports-contacts-analysis',
        'reports-monthlyreport-contacts-activities',
        'reports-orders-overview2',
        'reports-employee-supply',
        'reports-monthlyreport-contacts-activities',
        'sales',
    ],
    globalRegionsfilterLevels: 2,
    globalSearchActiveModules: [
        'institutions',
        'people',
        'b2c',
        'maps-tour',
        'events-overview',
        'admin-users',
        'admin-roles',
        'admin-characteristics',
        'admin-characteristic-groups',
        'admin-notifications',
        'admin-divisions',
        'admin-products',
        'admin-regions',
        'reports-orders-overview',
        'reports-orders-overview2',
    ],
    hideAdditional2Column: true,
    hideAdditional3Column: true,
    hideClearingConfirm: false,
    hideExternalCustomerId1Column: true,
    hideInstitutionAdditional2Column: true,
    hideInstitutionAdditional3Column: true,
    hideInstitutionExternalCustomerId1: true,
    hideNextAppointmentColumn: true,
    hidePeopleContactsFrequency: true,
    hidePeopleExternalCustomerId1Column: true,
    hidePeopleNextAppointment: true,
    hidePersonSpecializationFocusColumn: true,
    hierarchyDepartmentKey: '5',
    hierarchyRelatedSelectboxEnabled: true,
    institutionsListExportDepartments: true,
    institutionDeleteDefaultReason: null,
    institutionNotesDisplayedColumns: ['create_date', 'notes-institution', 'note-employee', 'title', 'note', 'delete'],
    institutionTypeRelatedSelectboxEnabled: true,
    institutionsAppendDisplayedColumns: ['empty', 'person-icon'],
    institutionsContactsDisplayedGridColumns: [
        'contact-start',
        'contact-type',
        'contact-method',
        'contact-person',
        'contact-info',
        'employee',
    ],
    institutionsDatafields: {
        bga_number: {
            label: 'BGA-Nummer',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        city: {
            label: 'Stadt',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        country: {
            label: 'Land',
            cwInputType: 'text',
            cwListentries: 'country',
            disabled: false,
            required: true,
            visible: true,
        },
        county: {
            label: 'Bundesland',
            cwInputType: 'select',
            cwListentries: 'county',
            disabled: true,
            required: false,
            visible: true,
        },
        create: {visible: true},
        district: {
            label: 'Bezirk',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        edit: {visible: true},
        erp_number: {
            label: 'ERP-Nummer',
            cwInputType: 'text',
            required: false,
            visible: false,
        },
        external_customer_id1: {
            label: 'Externe Kundennummer 1',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        external_customer_id2: {
            label: 'Externe Kundennummer 1',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        fax: {
            label: 'Fax',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        health_insurance_association: {
            label: 'Krankenkasse',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: true,
        },
        id: {
            label: 'ID',
            cwInputType: 'text',
            disabled: true,
            required: true,
            visible: true,
        },
        internet: {
            label: 'Webseite',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        mail: {
            label: 'E-Mail',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        name1: {
            label: 'Name 1',
            cwInputType: 'text',
            disabled: false,
            required: true,
            visible: true,
        },
        name2: {
            label: 'Name 2',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        name3: {
            label: 'Name 3',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        name4: {
            label: 'Name 4',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        onekey_number: {
            label: 'OneKey-Nummer',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        permament_establishment_number: {
            label: 'Betriebsnummer',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        phone1: {
            label: 'Telefon 1',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        phone2: {
            label: 'Telefon 2',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        phone3: {
            label: 'Telefon 3',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        street: {
            label: 'Straße',
            cwInputType: 'text',
            disabled: false,
            required: true,
            visible: true,
        },
        street_number_from: {
            label: 'Straßennummer von',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        street_number_to: {
            label: 'Straßennummer bis',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        supplementary_numbers: {
            label: 'Zusätzliche Nummern',
            visible: true,
        },
        type1: {
            label: 'Typ 1',
            cwInputType: 'select',
            cwListentries: 'institutionType1',
            disabled: false,
            required: true,
            visible: true,
        },
        type2: {
            label: 'Typ 2',
            cwInputType: 'select',
            cwListentries: 'institutionType2',
            disabled: false,
            required: false,
            visible: true,
        },
        parent: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        zipcode: {
            label: 'Postleitzahl',
            cwInputType: 'text',
            disabled: false,
            required: true,
            visible: true,
        },
    },
    institutionsFavoriteColumnEnabled: true,
    institutionsInvoicesDisplayedColumnsCustomerSpecific: [],
    institutionsPeopleDisplayedColumns: [
        'person-icon',
        'person-title-name',
        'person-function',
        'person-specialization',
        'contact',
        'favorite',
    ],
    institutionsPrependDisplayedColumns: ['select', 'institution-icon'],
    lockTickets: false,
    mailValidationMessage: 'E-Mail-Adresse ist nicht g\u00fcltig!',
    mailValidationRegex:
        '^[0-9a-zA-Z\\-_\\+öüäéèàâê&]([.]{0,1}[0-9a-zA-Z\\-_\\+öüäéèàâê&])*@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.)+[a-zA-Z]{2,9}$',
    mapsTourAdviceAwaitingResponseKeys: [2, 4],
    mapsTourAdviceCompleteKeys: [7, 8, 9],
    mapsTourAdviceFailureKeys: [7],
    mapsTourAdviceFaxForbiddenCategories: [],
    mapsTourAdviceButtonDisable: false,
    mapsTourAdviceSuccessKeys: [8, 9],
    mapsTourView: 'weekly',
    mapsTourViewDaysNumber: 5,
    mapsTourViewHourStart: 7,
    mapsTourMapEnableFitBounds: true,
    networkRelationShipKeys: ['1', '9', '7', '4', '8', '3', '10'],
    newInstitutionErpNumberDisabled: false,
    orderManagmentLink: '',
    orderPaymentConditionDependsOnCharacteristic: true,
    orderTrackingDatafields: {
        delivery_address: {
            disabled: false,
            required: false,
            visible: false,
        },
        delivery_date: {
            disabled: false,
            required: false,
            visible: true,
        },
        delivery_time: {
            disabled: true,
            required: false,
            visible: true,
        },
        employee: {
            disabled: true,
            required: false,
            visible: false,
        },
        id: {
            disabled: true,
            required: true,
            visible: true,
        },
        invoice_address: {
            disabled: false,
            required: false,
            visible: false,
        },
        order_address: {
            disabled: true,
            required: true,
            visible: true,
        },
        order_articlesum: {
            disabled: true,
            visible: true,
        },
        order_date: {
            disabled: false,
            required: true,
            visible: true,
        },
        order_discount: {
            disabled: false,
            required: false,
            visible: false,
        },
        order_discount_text: {
            disabled: true,
            visible: false,
        },
        order_range: {
            cwListentries: 'orderRange',
            disabled: false,
            required: false,
            visible: false,
        },
        order_status: {
            cwListentries: 'orderStatus',
            disabled: true,
            required: false,
            visible: true,
        },
        order_sum: {
            disabled: true,
            visible: false,
        },
        order_total: {
            disabled: true,
            visible: false,
        },
        order_total_before_discount: {
            disabled: true,
            visible: false,
        },
        order_type: {
            cwListentries: 'orderType',
            disabled: false,
            required: true,
            visible: true,
        },
        payment_condition: {
            cwListentries: 'orderPaymentCondition',
            disabled: false,
            required: false,
            visible: false,
        },
        text: {
            disabled: false,
            required: false,
            visible: true,
        },
        tracking_number: {
            disabled: true,
            required: false,
            visible: true,
        },
        tracking_url: {
            disabled: true,
            required: false,
            visible: true,
        },
        value_date: {
            disabled: false,
            required: false,
            visible: false,
        },
        wholesaler_address: {
            disabled: false,
            required: false,
            visible: false,
        },
    },
    orderTrackingItemColumns: {
        defaultMode: ['label', 'quantity_unit', 'amount', 'empty'],
        editMode: ['label', 'amount', 'empty', 'deleteColumn'],
    },
    orderVersion: 2,
    ordersDefaultMaxDeliveryDates: 1,
    passwordForgotLink: '',
    peopleAppendDisplayedColumns: ['empty', 'institution-icon'],
    peopleContactsDisplayedGridColumns: ['contact-start', 'contact-type', 'contact-method', 'contact-info', 'employee'],
    peopleDatafields: {
        assistant1: {
            label: 'Assistent 1',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        assistant2: {
            label: 'Assistent 2',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        assistant3: {
            label: 'Assistent 3',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        create: {visible: true},
        edit: {visible: true},
        erp_number: {
            label: 'ERP-Nummer',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        external_customer_id1: {
            label: 'Externe Kundennummer 1',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        external_customer_id2: {
            label: 'Externe Kundennummer 2',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        firstname: {
            label: 'Vorname',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        health_insurance_association_number: {
            label: 'Krankenkassennummer',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        id: {
            label: 'ID',
            cwInputType: 'text',
            disabled: true,
            required: true,
            visible: true,
        },
        internet: {
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        institution_phone1: {
            label: 'Telefonnummer Einrichtung',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData__addressaddon1: {
            label: 'Adresszusatz 1',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData__addressfield: {
            label: 'Adressfeld',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData__fax: {
            label: 'Fax',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData__internet: {
            label: 'Webseite',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        joinData__mail: {
            label: 'E-Mail',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData__mail_type: {
            label: 'E-Mail Typ',
            cwInputType: 'select',
            cwListentries: 'personMailType',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData__main_institution: {
            label: 'Haupt-Einrichtung',
            cwInputType: 'checkbox',
            disabled: true,
            required: false,
            visible: true,
        },
        joinData__person_function: {
            label: 'Funktion',
            cwInputType: 'select',
            cwListentries: 'personFunction',
            disabled: false,
            required: true,
            visible: true,
        },
        joinData__phone1: {
            label: 'Telefonnummer 1',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData__phone2: {
            label: 'Telefonnummer 2',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData__phone3: {
            label: 'Telefonnummer 3',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData_phone1_type: {
            label: 'Telefonnummer 1 Typ',
            cwInputType: 'select',
            cwListentries: 'personPhoneType',
            disabled: false,
            required: false,
            visible: false,
        },
        joinData_phone2_type: {
            label: 'Telefonnummer 2 Typ',
            cwInputType: 'select',
            cwListentries: 'personPhoneType',
            disabled: false,
            required: false,
            visible: true,
        },
        joinData_phone3_type: {
            label: 'Telefonnummer 3 Typ',
            cwInputType: 'select',
            cwListentries: 'personPhoneType',
            disabled: false,
            required: false,
            visible: false,
        },
        lastname: {
            label: 'Nachname',
            cwInputType: 'text',
            disabled: false,
            required: true,
            visible: true,
        },
        lifetime_doctornumber: {
            label: 'Arztnummer',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: true,
        },
        onekey_number: {
            label: 'OneKey-Nummer',
            cwInputType: 'text',
            disabled: true,
            required: false,
            visible: false,
        },
        person_salutation: {
            label: 'Anrede',
            cwInputType: 'select',
            cwListentries: 'personSalutation',
            disabled: false,
            required: true,
            visible: true,
        },
        person_salutation_letter: {
            label: 'Briefanrede',
            cwInputType: 'select',
            cwListentries: 'personSalutationLetter',
            disabled: false,
            required: true,
            visible: true,
        },
        person_specialization1: {
            label: 'Fachrichtung 1',
            cwInputType: 'select',
            cwListentries: 'personSpecialization1',
            disabled: false,
            required: true,
            visible: true,
        },
        person_specialization2: {
            label: 'Fachrichtung 2',
            cwInputType: 'select',
            cwListentries: 'personSpecialization2',
            disabled: false,
            required: false,
            visible: true,
        },
        person_specialization_focus: {
            label: 'Schwerpunkt Fachrichtung',
            cwInputType: 'select',
            cwListentries: 'personSpecializationFocus',
            disabled: false,
            required: false,
            visible: false,
        },
        person_title: {
            label: 'Titel',
            cwInputType: 'select',
            cwListentries: 'personTitle',
            disabled: false,
            required: false,
            visible: true,
        },
        name_suffix: {
            label: 'Namenszusatz',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
        type1: {
            label: 'Personentyp',
            cwInputType: 'select',
            cwListentries: 'personType1',
            disabled: false,
            required: true,
            visible: true,
        },
        person_greeting_letter: {
            label: 'Briefanrede',
            cwInputType: 'text',
            disabled: false,
            required: false,
            visible: false,
        },
    },
    peopleFavoriteColumnEnabled: true,
    peoplePrependDisplayedColumns: ['select', 'person-icon'],
    personDeleteDefaultReason: null,
    personFieldIsMandatoryInstitution: true,
    personFieldIsMandatoryPeople: true,
    personNotesDisplayedColumns: ['create_date', 'notes-institution', 'note-person', 'title', 'note', 'delete'],
    personNotesDisplayedColumnsCustomerSpecific: [],
    contactOverviewDisplayedFilterCustomerSpecific: [],
    personTypeRelatedSelectboxEnabled: true,
    phoneValidationMessage: 'Telefon und Faxnummern m\u00fcssen mit der L\u00e4ndervorwahl oder einer 0 beginnen.',
    phoneValidationRegex: '^((\\+[0-9]{2})|0)[0-9\\s\\-\\/]{2,}$',
    production: false,
    regionAssignmentDatafields: ['id', 'name'],
    regionSegmentsEnabled: false,
    enableOrderOverviewExcelExportButton: false,
    reportOrdersOverviewDisplayedColumns: [
        'select',
        'id',
        'order_date',
        'delivery_date',
        'order_name',
        'order_status',
        'employee',
        'order_type',
        'order_amount',
        'empty',
    ],
    reportsContactsListDisplayedColumns: [
        'contact_start',
        'responsible-employee',
        'contact-type',
        'contact-method',
        'contacts-overview-person',
        'contacts-overview-institution',
        'contact-topics-of-conversation',
        'contact-info',
        'empty',
    ],
    salesSalesDailyDisplayedColumns: [
        'select',
        'invoice-date',
        'order-erp-number',
        'institution-erp-number',
        'institution-name',
        'zipcode',
        'city',
        'invoice-positions',
        'invoice-total',
        'empty',
    ],
    invoiceDetailsDisplayedColumns: [
        'invoice-position',
        'product-erp-number',
        'product-label',
        'empty',
        'invoice-position-type',
        'position-unit-price',
        'position-amount',
        'position-price',
    ],
    invoiceDetailsDisplayedColumnsCustomerSpecific: [],
    sendStatus: {
        save: {10: '11'},
        send: {
            10: '20',
            11: '20',
        },
    },
    articleGridDisplayedColumns: [
        'label',
        'delivery_date',
        'order_item_delivery_institution_id',
        'amount',
        'unit_price',
        'discount',
        'total_price',
        'order_item_charge',
    ],
    articleGridDisplayedColumnsCustomerSpecific: [],
    settingsEnabled: true,
    settingsLanguageEnabled: false,
    settingsPasswordEnabled: true,
    settingsSelectDeputyEnabled: true,
    sexRelatedSelectboxEnabled: true,
    showBgaNumberColumn: true,
    showCustomSort: false,
    showDemoNetwork: false,
    showEdetailerLinks: false,
    showGlobalNotifications: true,
    showInstitutionNotesInstitutionName: false,
    showInstitutionNotesEmployee: true,
    showInstitutionNotesTitle: false,
    showNumberCharacteristics: true,
    showOneKeyNumberColumn: false,
    showOwnTurnoverColumn: false,
    showPersonDataInstitutions: true,
    showPersonNotesTitle: false,
    showRegionsfilterHomeButton: true,
    showSendButton: {
        forOrderStatus: ['10', '11'],
        show: true,
    },
    supportMailAddress: 'support@pharmakon.software',
    textAreaRequired: false,
    urlValidationMessage: 'URL ist nicht g\u00fcltig!',
    urlValidationRegex: '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$',
    useSalesAnalysisLayoutInProductView: false,
    yearlySalesChartType: 'bar',
    frontendTag: '3.2.0.8',
    enableNgSw: true,
    customerSmallLogoPath: '',
    customerBigLogoPath: '',
    customerUserIconMalePath: '',
    customerUserIconFemalePath: '',
    customerUserIconDefaultPath: '',
};
